'use strict';

var Statistic = function (Statistic) {
    /*  !! bei v2 kommen die daten in unixUtc! !!
        Daten kommen in Sekunden seit 1.1.2009 in Miniserver-Zeit -> schade!
        184032000 -> 1.Nov.2014
        1970-2009: 1230768000
     */
    var parseBinaryStats = function parseBinaryStats(bytes, nrOfOutputs, isUnix = false) {
        if (nrOfOutputs == null) throw new Error("need nrOfOutputs to parse stats!");
        /*var data = new DataStream(bytes, 0, DataStream.LITTLE_ENDIAN);
        var stats = [];
        while(!data.isEof()) {
            stats.push({
                ts: data.readUint32(),
                values: [].slice.call(data.readFloat64Array(nrOfOutputs))
            });
        }*/
        // this is about 4x faster!

        var dataView = new DataView(bytes),
            offset = 0,
            output = 0,
            stats = [],
            dp;

        while (offset < dataView.byteLength) {
            dp = {};
            dp.ts = dataView.getUint32(offset, true);
            if (!isUnix) {
                // convert to MS-Timestamp = add unix offset until 1.1.2009
                dp.ts += 1230768000;
            }

            offset += 4;
            dp.values = [];
            output = 0;

            while (output < nrOfOutputs) {
                dp.values[output] = dataView.getFloat64(offset, true);
                offset += 8;
                output++;
            }

            stats.push(dp);
        }

        return stats;
    };

    var asyncParseBinaryStats = function asyncParseBinaryStats(bytes, nrOfOutputs, isUnix = false) {
        return lxWorker.evalFunction(parseBinaryStats, [bytes, nrOfOutputs, isUnix]);
    };

    var serializeBinaryStats = function serializeBinaryStats(source) {
        //Debug.Statistic.Parsing && console.time("serializeBinaryStats");
        var nrOfOutputs = source[0].values.length;
        var lengthPerEntry = 4 + 8 * nrOfOutputs;
        var arrayBuffer = new ArrayBuffer(lengthPerEntry * source.length);
        var dataView = new DataView(arrayBuffer);
        var i,
            j,
            offset = 0;

        for (i = 0; i < source.length; i++) {
            dataView.setUint32(offset, source[i].ts - 1230768000
                /* remove timeoffset */
                , true);
            offset += 4;

            for (j = 0; j < nrOfOutputs; j++) {
                dataView.setFloat64(offset, source[i].values[j], true);
                offset += 8;
            }
        }
        /*var check = parseBinaryStats(dataView.buffer, nrOfOutputs);
        sortStatisticData(check);
        source.equals(check);*/


        //Debug.Statistic.Parsing && console.timeEnd("serializeBinaryStats");
        //Debug.Statistic.Parsing && console.log("serializeBinaryStats finished for", dataView.byteLength, "Bytes and", nrOfOutputs, "outputs");
        return dataView.buffer;
    };

    var asyncSerializeBinaryStats = function asyncSerializeBinaryStats(source) {
        return lxWorker.evalFunction(serializeBinaryStats, [source]);
    };

    Statistic.Parser = {
        parseBinaryStats: parseBinaryStats,
        asyncParseBinaryStats: asyncParseBinaryStats,
        serializeBinaryStats: serializeBinaryStats,
        asyncSerializeBinaryStats: asyncSerializeBinaryStats
    };
    return Statistic;
}(Statistic || {});
