'use strict';
{
    let ensureGlobal;

    if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
        ensureGlobal = self;
    } else {
        ensureGlobal = window;
    }

    ensureGlobal.Statistic = function (Statistic) {
        var REQUEST_MONTH_FORMAT = "YYYYMM",
            REQUEST_YEAR_FORMAT = "YYYY";
        /**
         * sorts data chronologically
         * @param data
         * @param from
         * @param to
         */

        var sortStatisticData = function sortStatisticData(data, from, to) {
            //Debug.Statistic.Processing && console.info("sortStatisticData: " + data.length);
            //Debug.Statistic.Processing && console.time("sortStatisticData");
            //Debug.Statistic.Processing && console.time("tsChecking");

            var checkRange = function (dp) {
                // check if some ts is < from
                if (dp.ts < from) {
                    //Debug.Statistic.Processing && console.log("    found out-of-range ts (ts <)", dp.ts);
                    data.splice(data.indexOf(dp), 1); // remove it, it's a wrong ts!

                    return false;
                } // check if some ts is > to


                if (dp.ts > to) {
                    //Debug.Statistic.Processing && console.log("    found out-of-range ts (ts >)", dp.ts);
                    data.splice(data.indexOf(dp), 1); // remove it, it's a wrong ts!

                    return false;
                }

                return true;
            };

            var i = 0;

            while (i < data.length) {
                if (checkRange(data[i])) {
                    i++; // only inc. i if the dp isn't deleted
                }
            }

            //Debug.Statistic.Processing && console.timeEnd("tsChecking");
            //Debug.Statistic.Processing && console.log("    length after ts checking", data.length);
            var clone = data.slice(0); // clone array before sorting to be able to work with correct indexes even while sorting!

            data.sort(function (a, b) {
                if (a.ts > b.ts) {
                    return 1;
                } else if (a.ts < b.ts) {
                    return -1;
                } else {
                    //Debug.Statistic.Processing && console.log("    found same ts", a.ts); // now look at the index of the elements in the array, and keep previous order

                    if (clone.indexOf(a) < clone.indexOf(b)) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            });
            //Debug.Statistic.Processing && console.timeEnd("sortStatisticData");
            return data;
        };
        /**
         * goes through all data points and clears out neighbouring duplicates ([0, 0, 1, 1, 1] -> [0, 1]) - first change stays!
         * @param data to check
         * @param {boolean} rmValueDuplicates if datapoints with duplicate values should be removed
         * @param {boolean} rmTSDuplicates if datapoints with duplicate timestamps should be removed
         * @param {boolean} rmTSandValueDuplicates if datapoints with duplicate timestamps and values should be removed
         * @param {boolean} [compareAll] if all duplicates should be removed
         */


        var removeDuplicateDatapoints = function removeDuplicateDatapoints(data, rmValueDuplicates, rmTSDuplicates, rmTSAndValueDuplicates, compareAll) {
            //Debug.Statistic.Processing && console.time("removeDuplicateDatapoints");
            //Debug.Statistic.Processing && console.info("removeDuplicateDatapoints: " + data.length);

            for (var i = 0; i < data.length; i++) {
                if (compareAll) {
                    for (var j = 1; j < data.length; j++) {
                        if (j !== i && data[i] && data[j]) {
                            if (rmTSAndValueDuplicates && data[i].value === data[j].value && data[i].ts === data[j].ts) {
                                //Debug.Statistic.Processing && console.info("removed duplicate:", data[i].value, "ts:", data[i].ts);
                                data.splice(i, 1);
                                i--;
                                break;
                            }

                            if (rmValueDuplicates && data[j].value === data[i].value) {
                                //Debug.Statistic.Processing && console.info("removed same value duplicate:", data[i].value, "ts:", data[i].ts);
                                //Debug.Statistic.Processing && console.info("              duplicate with:", data[j].value, "ts:", data[j].ts);
                                data.splice(j, 1); // first ts wins! (eg. every change)

                                i--;
                                break;
                            }

                            if (rmTSDuplicates && data[j].ts === data[i].ts) {
                                //Debug.Statistic.Processing && console.info("removed same timestamp duplicate:", data[i].ts, "value:", data[i].value);
                                //Debug.Statistic.Processing && console.info("                  duplicate with:", data[j].ts, "value:", data[j].value);
                                data.splice(i, 1); // last ts wins! (eg. time shift)

                                i--;
                                break;
                            }
                        }
                    }
                } else {
                    if (data[i + 1]) {
                        if ((rmValueDuplicates && rmTSDuplicates || rmTSAndValueDuplicates) && data[i].value === data[i + 1].value && data[i].ts === data[i + 1].ts) {
                            //Debug.Statistic.Processing && console.info("removed duplicate:", data[i].value, "ts:", data[i].ts);
                            data.splice(i + 1, 1);
                            i--;
                        } else if (rmValueDuplicates && data[i].value === data[i + 1].value) {
                            //Debug.Statistic.Processing && console.info("removed same value duplicate:", data[i].value, "ts:", data[i].ts);
                            //Debug.Statistic.Processing && console.info("              duplicate with:", data[i + 1].value, "ts:", data[i + 1].ts);
                            data.splice(i + 1, 1); // first ts wins! (eg. every change)

                            i--;
                        } else if (rmTSDuplicates && data[i].ts === data[i + 1].ts) {
                            //Debug.Statistic.Processing && console.info("removed same timestamp duplicate:", data[i].ts, "value:", data[i].value);
                            //Debug.Statistic.Processing && console.info("                  duplicate with:", data[i + 1].ts, "value:", data[i + 1].value);
                            data.splice(i, 1); // last ts wins! (eg. time shift)

                            i--;
                        }
                    }
                }
            }

            //Debug.Statistic.Processing && console.timeEnd("removeDuplicateDatapoints");
            //Debug.Statistic.Processing && console.info("removeDuplicateDatapoints: " + data.length);
            return data;
        };
        /**
         * calculates stuff for accumulated statistics!
         * @param dataPoints
         * @param date
         * @param range
         * @param [lastIntermediateDp]
         * @returns {Array} calculated dataPoints
         */


        var calculateAccumulatedStats = function calculateAccumulatedStats(dataPoints, date, range, lastIntermediateDp) {
            //Debug.Statistic.Processing && console.time("calculateAccumulatedStats");
            removeDuplicateDatapoints(dataPoints, false, true);
            var RESET_IDENTIFICATION_LIMIT_PERCENTAGE = 5;
            var dateObj = moment.utc(date, [REQUEST_MONTH_FORMAT, REQUEST_YEAR_FORMAT]); //var rangeWidth = getStepWidthForRange(range, startOfMonth.clone());

            var fromTime = lastIntermediateDp && lastIntermediateDp.ts ? moment.utc(lastIntermediateDp.ts * 1000) : dateObj.clone().startOf(range),
                fromTimeUnix = fromTime.unix(),
                toTime = date.length === 4 ? dateObj.endOf("year") : dateObj.endOf("month"),
                // if we request a year, we only have "YYYY" -> length 4!
                toTimeUnix = toTime.unix();
            var i = 0;

            while (dataPoints[i] && dataPoints[i].ts < fromTimeUnix) {
                // this is either the startOf(range) or the lastIntermediate.ts!
                dataPoints.shift();
            } // go back to startOf(range)!


            fromTime = fromTime.startOf(range);
            fromTimeUnix = fromTime.unix();

            if (lastIntermediateDp) {
                // we have an intermediateDp!
                if (lastIntermediateDp.ts) {
                    // we have an intermediateDp with ts, add it!
                    dataPoints.unshift(lastIntermediateDp);
                } else {
                    // set the last point of all dataPoints to the lastIntermediate
                    // IMPORTANT: only set the props, to keep the reference!
                    lastIntermediateDp.ts = dataPoints[dataPoints.length - 1].ts;
                    lastIntermediateDp.value = dataPoints[dataPoints.length - 1].value;
                }
            }

            var result = [],
                currentPackageTime = fromTime.clone(),
                currentPackage;

            while (dataPoints[i] && dataPoints[i].ts <= toTimeUnix) {
                currentPackage = {
                    ts: currentPackageTime.unix(),
                    value: 0
                }; // set next package time

                currentPackageTime.add(1, range); // complete range-package

                var nextPackageTime = currentPackageTime.unix(); // check if we have datapoints in the package range anyway

                if (dataPoints[i].ts > nextPackageTime) {
                    continue; // no points, don't add package!
                }

                while (dataPoints[i] && dataPoints[i].ts <= nextPackageTime) {
                    if (dataPoints[i - 1]) {
                        var delta = dataPoints[i].value - dataPoints[i - 1].value; //console.log("delta", delta, " from ts", dataPoints[i-1].ts, "to ts", dataPoints[i].ts);

                        if (delta > 0) {
                            currentPackage.value += delta;
                        } else if (delta < 0) {
                            // counter reset!
                            // check if it is really a reset or more likely a small variation in the values due to "save in miniserver"
                            var percentage = Math.abs(delta) / dataPoints[i - 1].value * 100; // if the drop is greater than 5% of the previous value, it's a reset

                            if (percentage > RESET_IDENTIFICATION_LIMIT_PERCENTAGE) {
                                currentPackage.value += dataPoints[i].value;
                            } else {// otherwise it's most likely a variation due to saving into the miniserver
                            }
                        } else {// otherwise, don't do anything. the delta's too small - so don't mind it
                        }
                    }

                    i++;
                }

                result.push(currentPackage);
            }

            //Debug.Statistic.Processing && console.timeEnd("calculateAccumulatedStats");
            //Debug.Statistic.Processing && console.info("calculateAccumulatedStats: " + result.length);
            return result;
        };
        /**
         * Detects min, max and outliers. Excludes values that exceed the regular range, which is limited by the
         * outlierLimit - this way they aren't part of the max-value-computation.
         * like in Mantis 6192
         */


        var checkAccumulatedOutliers = function checkAccumulatedOutliers(data) {
            //Debug.Statistic.Processing && console.log("checkAccumulatedOutliers");
            var mmm = getMinMaxAndMedianOfPoints(data),
                limit = (mmm.median - mmm.min) * 3 + mmm.median,
                mm = getMinAndMaxOfPoints(data, limit);
            //Debug.Statistic.Processing && console.log("            min: " + mm.min);
            //Debug.Statistic.Processing && console.log("            max: " + mm.max);
            //Debug.Statistic.Processing && console.log("   outlierLimit: " + limit);
            return {
                data: data,
                min: mm.min,
                max: mm.max,
                outlierLimit: limit
            };
        };
        /**
         * Calculates the min and max of dataPoints, will exclude ridiculously high values from the max computation, check
         * the outlierLimit - there might be data points exceeding it, which will be above the "max" value.
         * @note This function ignores entry points with the noEntryDefine value in its min and max value calculations
         * @param points
         * @param {Number} [limit]
         * @returns {{min: Number, max: Number}}
         */


        var getMinAndMaxOfPoints = function getMinAndMaxOfPoints(points, limit) {
            //Debug.Statistic.Processing && console.log("getMinAndMaxOfPoints: limit: " + limit);
            var mm = [NaN, NaN],
                values = [];

            if (points.length) {
                points.forEach(function (point) {
                    // Ignore every "NoDataEntry"
                    if (point.isNoDataEntry) {
                        return;
                    }

                    if (limit && point.value > limit) {
                        values.push(limit); // Push the limit instead of the actual value to not exceed the limit
                    } else if (!isNaN(point.value)) {
                        values.push(point.value);
                    }
                }); // ATTENTION: Don't use Math.min, as we may exceed the callstack on large statistics

                mm = values.reduce(function (reducedMM, val) {
                    return [Math.min(reducedMM[0], val), Math.max(reducedMM[1], val)];
                }, [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]); // ATTENTION: Don't use Math.min, as we may exceed the callstack on large statistics
                // === BAD CODE ===
                //mm.min = Math.min.apply(Math, values);
                //mm.max = Math.max.apply(Math, values);
                // === BAD CODE ===
            }

            return {
                min: mm[0],
                max: mm[1]
            };
        };
        /**
         * calculates min, max and median of dataPoints
         * @param points
         * @returns {{min: Number, max: Number, median: Number}}
         */


        var getMinMaxAndMedianOfPoints = function getMinMaxAndMedianOfPoints(points) {
            //Debug.Statistic.Processing && console.time("getMinMaxAndMedianOfPoints");
            var mmm = {
                min: NaN,
                max: NaN,
                median: NaN
            };

            if (points.length) {
                mmm.min = points[0].value;
                mmm.max = points[0].value;
                var sum = 0;

                for (var i = 0; i < points.length; i++) {
                    if (points[i].value < mmm.min) {
                        mmm.min = points[i].value;
                    } else if (points[i].value > mmm.max) {
                        mmm.max = points[i].value;
                    }

                    if (points[i].value > 0) {
                        // igonre 0!
                        sum += points[i].value;
                    }
                }

                mmm.median = sum / points.length;
            }

            //Debug.Statistic.Processing && console.timeEnd("getMinMaxAndMedianOfPoints");
            return mmm;
        };
        /**
         * goes through all outputs and reduces it to the given indexes (for multiple charts..)
         * @param data array to reduce
         * @param indexes array of indexes
         * @param noEntryDefine number which defines an entry as "No Entry"
         * @returns {Array} reduces data array
         */


        var reduceStatisticDataToOutputs = function reduceStatisticDataToOutputs(data, indexes, noEntryDefine) {
            //Debug.Statistic.Processing && console.time("reduceStatisticDataToOutputs");
            var outputsData = [],
                outputData; // make function to speed up for-loop!

            var prepareOutputs = function () {
                if (indexes.length === 1) {
                    return function reduceToOne(data) {
                        var value = data.values[indexes[0]];
                        return {
                            ts: data.ts,
                            value: value,
                            isNoDataEntry: value === noEntryDefine
                        };
                    };
                } else {
                    return function reduceToMultiple(data) {
                        // check if it works!
                        var reducedData = {
                            ts: data.ts,
                            values: []
                        };

                        for (var j = 0; j < indexes.length; j++) {
                            reducedData.values.push(data.values[indexes[j]]);
                        }
                    };
                }
            }();

            for (var i = 0; i < data.length; i++) {
                outputData = prepareOutputs(data[i]);
                outputData && outputsData.push(outputData);
            }

            //Debug.Statistic.Processing && console.timeEnd("reduceStatisticDataToOutputs");
            //Debug.Statistic.Processing && console.info("reduceStatisticDataToOutputs: " + outputsData.length);
            return outputsData;
        };
        /**
         * merges data from starting at last ts from data package
         * @param data
         * @param restData
         * @returns {Array} new data array
         */


        var mergeStatisticData = function mergeStatisticData(data, restData) {
            //Debug.Statistic.Processing && console.time("mergeStatisticData");
            var lastTS = data[data.length - 1].ts,
                newData = [];

            for (var i = 0; i < restData.length; i++) {
                if (restData[i].ts >= lastTS) {
                    // also add duplicate timestamps!
                    data.push(restData[i]);
                    newData.push(restData[i]);
                }
            }

            //Debug.Statistic.Processing && console.timeEnd("mergeStatisticData");
            //Debug.Statistic.Processing && console.info("mergeStatisticData: " + newData.length);
            return newData;
        };
        /**
         * Adds missing datapoints to the statistic data to add the duplicate datapoints on the end of the statistic
         * Only add missing datapoints to Linecharts. Barcharts will be falsified!
         * @note The Miniserver is not writing duplicate data points to the SD Card, thus we need to add these missing points
         * @param data
         * @param frequency
         * @param visuType The visu type of the statistic (ensureGlobal.Statistic.Type)
         * @param msNowUnix The current miniserver time as unix timestamp
         */


        var addMissingDatapoints = function addMissingDatapoints(data,
                                                                 frequency,
                                                                 visuType,
                                                                 msNowUnix,
                                                                 Statistic = ensureGlobal.Statistic) {
            var lastDataPoint,
                frequencyInSeconds,
                missingEntriesCnt,
                freqIdx = Object.values(Statistic.Frequency).indexOf(frequency),
                missingDpTs;

            if (frequency > Statistic.Frequency.EVERY_CHANGE && frequency < Statistic.Frequency.INTERVAL_5 && visuType === Statistic.Type.LINE_CHART) {
                // Extract the frequency in minutes from the enums name
                frequencyInSeconds = parseInt(Object.keys(Statistic.Frequency)[freqIdx].split("_")[1]) * 60;
                lastDataPoint = {...data.slice(-1)[0]}; // Calculate how many duplicate entries exist, this value is used to calculate the timestamp of the entry we need to add

                missingEntriesCnt = Math.floor((msNowUnix - lastDataPoint.ts) / frequencyInSeconds);

                if (missingEntriesCnt) {
                    missingDpTs = lastDataPoint.ts + missingEntriesCnt * frequencyInSeconds; // Don't add any duplicate entries!

                    if (lastDataPoint.ts !== missingDpTs) {
                        lastDataPoint.ts = missingDpTs;
                        data.push(lastDataPoint);
                    }
                }
            }

            return data;
        };

        Statistic.Processor = {
            sortStatisticData: sortStatisticData,
            removeDuplicateDatapoints: removeDuplicateDatapoints,
            addMissingDatapoints: addMissingDatapoints,
            calculateAccumulatedStats: calculateAccumulatedStats,
            getMinAndMaxOfPoints: getMinAndMaxOfPoints,
            getMinMaxAndMedianOfPoints: getMinMaxAndMedianOfPoints,
            reduceStatisticDataToOutputs: reduceStatisticDataToOutputs,
            mergeStatisticData: mergeStatisticData,
            checkAccumulatedOutliers: checkAccumulatedOutliers
        };
        return Statistic;
    }(ensureGlobal.Statistic || {});
}
