'use strict';

try {
    let ensureGlobal;

    if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
        ensureGlobal = self;
    } else {
        ensureGlobal = window;
    }

    ensureGlobal.StatisticV2 = (function(StatisticV2) {

        StatisticV2.combineChildNodeStatistics = function combineChildNodeStatistics(existingStats, newStats) {
            Debug.StatisticV2_Combine && console.log("StatisticV2", "_combineChildNodeStatistics");

            if (!newStats.header) {
                console.error("StatisticV2", "_combineChildNodeStatistics: newStats missing header: ", newStats);
                return existingStats;
            } else if(!newStats.data) {
                console.error("StatisticV2", "_combineChildNodeStatistics: newStats missing data: ", newStats);
                return existingStats;
            } else if (!existingStats.header) {
                console.error("StatisticV2", "_combineChildNodeStatistics: existing stats missing header: ", newStats);
                return newStats;
            }
            // existing and current look like this:
            // { data: {ts:number, values:numbers[]}[], header: { output:string, format:string, title:string} }

            // at first create a combined header list.
            // also create a list, that specifies to what existing value index the new values need to be added.
            let combinedHeaders = [...existingStats.header],
                foundHeader = false,
                foundHeaderIdx = -1,
                newStatValueIdxList = []; // where to put the values to in the existing dp?
            newStats.header.forEach((newHeader, newHeaderIdx) => {

                // check if the header is already present in the existing stats
                combinedHeaders.some((existingHeader, existingHeaderIdx) => {
                    if (existingHeader.output === newHeader.output) {
                        foundHeader = existingHeader;
                        foundHeaderIdx = existingHeaderIdx;
                    }
                });

                // track where to what existing value the new values need to be added.
                if (foundHeader) {
                    newStatValueIdxList[newHeaderIdx] = foundHeaderIdx
                } else {
                    combinedHeaders.push(newHeader);
                    newStatValueIdxList[newHeaderIdx] = combinedHeaders.length - 1;
                }
            })
            Debug.StatisticV2_Combine && console.log("StatisticV2", "  > combined headers: ", combinedHeaders);
            Debug.StatisticV2_Combine && console.log("StatisticV2", "  >  newStatValueIdx: ", newStatValueIdxList);

            // when every change is active, keep in mind that the last dp's value is still valid until a new DP arrives
            const shouldUseLastDpValues = existingStats.mode === 1 || existingStats.mode === 7; // 1 = max 1 per minute, 7 = every change
            let lastUsedNewDp,
                lastUsedExistingDp;

            // combine datapoints
            let nrOfValuesPerDp = combinedHeaders.length;
            const combineDataPoints = (existingDp, newDp, lastDpTs) => {
                let createdDp = {
                        ts: (existingDp ? existingDp.ts : newDp.ts),
                        values: Array(nrOfValuesPerDp)
                    },
                    storeageIdx;

                if (createdDp.ts <= lastDpTs) {
                    Debug.StatisticV2_Combine && console.warn("StatisticV2", "      * IGNORING DP - lastDpTs = " + lastDpTs +
                        ", ex= " + JSON.stringify(existingDp) + " + new=" + JSON.stringify(newDp) + " = " +
                        new LxDate((createdDp.ts) * 1000, false).format(DateType.DateAndTimeShortWithSeconds));
                    return null;
                }

                if (existingDp || (shouldUseLastDpValues && lastUsedExistingDp)) {
                    (existingDp || lastUsedExistingDp).values.forEach((existingVal, existingValIdx) => {
                        createdDp.values[existingValIdx] = existingVal;
                    });
                    lastUsedExistingDp = shouldUseLastDpValues ? existingDp : null;
                }

                if (newDp || (shouldUseLastDpValues && lastUsedNewDp)) {
                    (newDp || lastUsedNewDp).values.forEach((newVal, newValIdx) => {
                        storeageIdx = newStatValueIdxList[newValIdx];
                        if (createdDp.values[storeageIdx] === undefined) {
                            createdDp.values[storeageIdx] = newVal;
                        } else {
                            createdDp.values[storeageIdx] += newVal;
                        }
                    });
                    lastUsedNewDp = shouldUseLastDpValues ? newDp : null;
                }
                return createdDp;
            }

            let iExisting = 0;
            let iNew = 0,
                lastDpTs = -1,
                newDp,
                existingDp,
                combinedDp,
                iResult = 0,
                letResDp = Array(existingStats.data.length);

            let prevExIdx = -1,
                prevNewIdx = -1,
                breakLoop = false;
            Debug.StatisticV2_Combine && console.log("StatisticV2", "  >  combine datapoints! existing=" +
                existingStats.data.length + ", new=" + newStats.data.length);
            do {
                newDp = iNew < newStats.data.length ? newStats.data[iNew] : null;
                existingDp = iExisting < existingStats.data.length ? existingStats.data[iExisting] : null;

                if (newDp && existingDp && newDp.ts === existingDp.ts) {
                    Debug.StatisticV2_Combine && console.log("StatisticV2", "      "+ iNew + " - " + iExisting + " => exact match!", newDp, existingDp);
                    // exact match --> use.
                    combinedDp = combineDataPoints(existingDp, newDp, lastDpTs);
                    iNew++;
                    iExisting++;
                } else if ((!existingDp && newDp) || (newDp && existingDp && newDp.ts < existingDp.ts)) {
                    Debug.StatisticV2_Combine && console.log("StatisticV2", "      "+ iNew + " - " + iExisting + " => new one first!", newDp, existingDp);
                    // new is first
                    combinedDp = combineDataPoints(null, newDp, lastDpTs);
                    iNew++;
                } else if (existingDp) {
                    Debug.StatisticV2_Combine && console.log("StatisticV2", "      "+ iNew + " - " + iExisting + " => existing one first!", newDp, existingDp);
                    combinedDp = combineDataPoints(existingDp, null, lastDpTs);
                    iExisting++;
                } else {
                    console.log("StatisticV2", "_combineChildNodeStatistics encountered an invalid situation!");
                    console.log("StatisticV2", "      iNew=" + iNew + " - iExisting=" + iExisting + " => neither new nor existing dp!");
                    console.log("StatisticV2", "   newStats: " + JSON.stringify(newStats.header) + ", dps = " + newStats.data.length);
                    for (let iix = iNew - 10; iix < iNew + 10; iix++) {
                        if (iix >= 0 && iix < newStats.data.length) {
                            console.log("StatisticV2", "      " + iix + " = " + JSON.stringify(newStats.data[iix]));
                        }
                    }
                    console.log("StatisticV2", "    exStats: " + JSON.stringify(existingStats.header) + ", dps = " + existingStats.data.length);
                    for (let jix = iExisting - 10; jix < iExisting + 10; jix++) {
                        if (jix >= 0 && jix < existingStats.data.length) {
                            console.log("StatisticV2", "      " + jix + " = " + JSON.stringify(existingStats.data[jix]));
                        }
                    }
                    //iNew++;
                    //iExisting++;
                }
                lastDpTs = combinedDp ? combinedDp.ts : lastDpTs;
                if (combinedDp) {
                    letResDp[iResult++] = combinedDp;
                }

                if (prevExIdx >= 0 && prevExIdx === iExisting && prevNewIdx >= 0 && iNew === prevNewIdx) {
                    console.error("StatisticV2", "_combineChildNodeStatisticsLoop detected, break it! iExisting remained at " + iExisting + " and iNew remained at " + iNew);
                    breakLoop = true;
                }
                prevExIdx = iExisting;
                prevNewIdx = iNew;

            } while ((iExisting < existingStats.data.length || iNew < newStats.data.length) && !breakLoop);

            if (existingStats.mode !== newStats.mode) {
                console.error("StatisticV2", "Combining statistics recorded with different modes!");
                console.error("StatisticV2", "     existing mode = " + existingStats.mode);
                console.error("StatisticV2", "     new stat mode = " + newStats.mode);
            }

            if (breakLoop && Array.isArray(letResDp)) {
                console.error("StatisticV2", "combiningDps broke loop, (headers=" + JSON.stringify(combinedHeaders) + "), res = " + letResDp.length);
                for (var rix = letResDp.length - 1; rix >= letResDp.length - 21 && rix >= 0; rix--) {
                    console.log("StatisticV2", "      " + rix + " = " + JSON.stringify(letResDp[rix]));
                }
            }

            Debug.StatisticV2_Combine && console.log("StatisticV2", "   existing = ", existingStats);
            Debug.StatisticV2_Combine && console.log("StatisticV2", "   newStats = ", newStats);
            let result = {
                mode: newStats.mode,
                header: combinedHeaders,
                data: Array.isArray(letResDp) ? letResDp.filter((dp) => {
                    return !!dp; // ensures no undefined datapoints leave this fn
                }) : []
            }
            Debug.StatisticV2_Combine && console.log("StatisticV2", "     result = ", result);
            return result;
        }

        return StatisticV2;
    })(ensureGlobal.StatisticV2 || {})
} catch (e) {
    console.error(e);
    debugger;
}
